<template>
  <div style="padding: 0 10px; width: 100%">
    <v-col v-if="loading" md="12" style="padding: 0">
      <v-expansion-panels class="mb-6" elevation="1">
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            style="font-weight: bold"
          >
            Lihat Detail Pengembalian
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container style="min-height: 50vh">
              <v-row>
                <v-col md="5" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="8" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Nomor Pengembalian
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        readonly
                        :value="response.doc_return_no"
                        dense
                        style="margin: 0"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col md="2" style="padding: 0"></v-col>
                <v-col md="5" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="8" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Dikembalikan Oleh
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        readonly
                        :value="response.pic_return_name"
                        dense
                        style="margin: 0"
                      />
                    </v-col>
                  </div>
                </v-col>

                <v-col md="5" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="8" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Tgl Pengembalian
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        readonly
                        :value="convertDate(response.return_time)"
                        style="margin: 0"
                      />
                    </v-col>
                  </div>
                </v-col>

                <v-col md="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="8" style="padding: 0">
                      <p style="margin: 0 0 0 5px">Keterangan</p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-textarea
                        readonly
                        :value="response.description"
                        dense
                        auto-grow
                        outlined
                        style="margin: 0"
                      />
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
// import { mapMutations } from "vuex";
export default {
  name: 'Document-Return',
  props: ['loanId', 'permission'],
  data() {
    return {
      eSanqua: buildType.apiURL('esanqua'),
      response: null,
      loading: false
    }
  },
  created() {
    this.loading = false
    this.loadDocumentReturn()
  },
  methods: {
    loadDocumentReturn() {
      axios
        .get(`${this.eSanqua}archive_document/return_by_loan/${this.loanId}`)
        .then(res => {
          console.log(res.data.data)
          this.response = res.data.data
          setTimeout(() => {
            this.loading = true
          }, 500)
        })
        .catch(err => {
          console.log(err)
        })
    },
    convertDate(raw) {
      const date = new Date(raw)
      const str = date.toLocaleString()

      const day = str.slice(0, str.indexOf('/'))
      let month = str.slice(str.indexOf('/') + 1, str.lastIndexOf('/'))
      const year = str.slice(str.lastIndexOf('/') + 1)

      switch (month) {
        case '01':
          month = 'JAN'
          break
        case '02':
          month = 'FEB'
          break
        case '03':
          month = 'MAR'
          break
        case '04':
          month = 'APR'
          break
        case '05':
          month = 'MAY'
          break
        case '06':
          month = 'JUN'
          break
        case '07':
          month = 'JUL'
          break
        case '08':
          month = 'AGS'
          break
        case '09':
          month = 'SEP'
          break
        case '10':
          month = 'OKT'
          break
        case '11':
          month = 'NOV'
          break
        case '12':
          month = 'DES'
          break
      }

      return day + ' ' + month + ' ' + year
    }
  }
}
</script>
<style lang="scss" scoped></style>
